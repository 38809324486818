import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import FaqBlock from './FaqBlock'
import styles from './FaqList.module.css'

const FaqList = (props) => {
  let faqs = props.data.faqItems.edges
  let rem = faqs.length % 3

  // always have 3 items per row
  // we add an new object with attribute fill=true to fill up
  // empty spaces
  if (rem !== 0) {
    let fill = 3 - rem
    for (let i = 0; i < fill; i++) {
      faqs.push({ fill: true, key: i })
    }
  }

  // create the faq nodes, use an empty node where necessary
  let faqNodes = []
  for (let edge of faqs) {
    if (edge.fill) {
      faqNodes.push(<FaqBlock key={edge.key} title="" description="" />)
    } else {
      faqNodes.push(
        <FaqBlock
          key={edge.node.frontmatter.title}
          title={edge.node.frontmatter.title}
          description={edge.node.html}
        />
      )
    }
  }

  return (
    <div className={styles.root}>
      <h1 className="text-center">FAQ</h1>
      <div className={styles.faqList}>{faqNodes}</div>
    </div>
  )
}

const query = graphql`
  query FaqListQuery {
    faqItems: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "faq" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          html
        }
      }
    }
  }
`

export default (props) => (
  <StaticQuery
    query={query}
    render={(data) => <FaqList data={data} {...props} />}
  />
)
