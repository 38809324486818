import React from 'react'
import styles from './pricing.module.css'
import Layout from '../layouts/index'
import Helmet from 'react-helmet'
import FaqList from '../components/FaqList'
import NewPlans from '../components/NewPlans'

const PricingPage = () => {
  return (
    <Layout>
      <Helmet
        title={
          'Pricing plans, startups to large companies | Suggested - Build better products'
        }
      ></Helmet>
      <div className="page">
        <h1 className="text-center">Designed to help you grow</h1>

        <div className={styles.plansContainer}>
          <NewPlans />
        </div>

        <div className={styles.faqContainer}>
          <FaqList />
        </div>
      </div>
    </Layout>
  )
}

export default PricingPage
