import React from 'react'
import styles from './FaqBlock.module.css'

export const FaqBlock = ({ title, description }) => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>{title}</div>
      <div
        className={styles.html}
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    </div>
  )
}

export default FaqBlock
