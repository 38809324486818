import React from 'react'
import styles from './NewPlans.module.css'
import featuresYaml from '../../data/features-home.yaml'
import SignupButton from '../components/SignupButton'

const NewPlans = (props) => {
  return (
    <div className={styles.root}>
      <div className={styles.priceBox}>
        <div className={styles.header}>Simple pricing</div>
        <div className={styles.content}>
          <p>Get started with our 15 day free trial, then it's:</p>
          <div className={styles.price}>$49/month</div>
          <p>Yes, it's as simple as that. No complicated tiered plans.</p>
          <SignupButton subtitle="No credit card required." />
        </div>
      </div>
      <div className={styles.featureBox}>
        <div className={styles.header}>All inclusive</div>
        <div className={styles.content}>
          <p>
            You'll get access to <b>all</b> available features.
          </p>
          <div className={styles.features}>
            {featuresYaml.features.map((feature) => (
              <div className={styles.feature}>
                <i className={feature.icon}></i> {feature.title}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewPlans
